<template>
    <div>
      <stepper :page="page" :payroll_id="payroll_id"></stepper>
    </div>
</template>
<script>
  import config from "../../config.js";
  // import Search from "./search";
  import Datepicker from "vuejs-datepicker";
  import vSelect from "vue-select";
  import "vue-select/dist/vue-select.css";
  import moment from "moment";
  import stepper from "./stepper.vue";
  
  export default {
   
    name: "Tables",
    components: { Datepicker, vSelect, stepper },
    data() {
      return {
        company_id : config.global_user_data.company_id,
        page: null,
        payroll_id : false
      }
    },
    beforeMount() {
      if(this.$route.params.id) {
          this.page = 2;
          this.payroll_id = this.$route.params.id
      }
    },
    mounted() {
      
    },
    methods: {
      reloadPage() {
        this.$router.push('/main/payroll')
      }
    },
  };
  </script>
  