<template>
<form action="#" class="form">
  <CCard class="px-4 pt-2 mb-2" v-show="!(payroll_status =='approved' || payroll_status =='paid')">
      <!-- <h4 class="text-center">Process Payroll</h4> -->
      <!-- Progress bar -->
      <div class="progressbar">
        <div class="progress" id="progress"></div>
        
        <div
          class="progress-step progress-step-active"
          data-title="Date"
        ></div>
        <div class="progress-step" data-title="Payments"></div>
        <div class="progress-step" data-title="Review"></div>
      </div>
  </CCard>
      <!-- Steps -->
      <div class="form-step form-step-active">
        <template v-if="shown['step1']">
          <step1 ref="payroll_range" :no_data="no_data" @generate="generate" />
        </template>
        
      </div>
      <div class="form-step">
        <template v-if="shown['step2']">
          <step2 ref="payroll_info" @next="nextBtn" @prev="prevBtn" :from="from" :to="to" :payroll="payroll_data" :status="payroll_status" />
        </template>
      </div>
      <div class="form-step">
        <step3 v-if="shown['step3']" ref="payroll_summary" :from="from" :to="to" :payroll="payroll_data" @submitpayroll="submit" @prev="prevBtn" :status="payroll_status" />
      </div>
    </form>
</template>
<script>

import step1 from './component/step1.vue';
import step2 from './component/step2.vue';
import step3 from './component/step3.vue';
import axios from '../../axios';
import config from '../../config';
import moment from 'moment';

export default {
    name : 'stepper',
    props: ['company','page','payroll_id'],
    data() {
      return {
        config,
        formStepsNum : 1,
        progressActive: [],
        shown: {
          step1 : true,
          step2 : false,
          step3 : false
        },
        payroll_data : [],
        from: null,
        to: null,
        no_data: null,
        payroll_status : null
      }
    },
    components: {step1, step2, step3},
    beforeMount() {
      if(this.payroll_id)
        this.reviewPayroll()
    },
    computed: {
      isPrevBtnDisabled() {
        return (status, page) => {
          if (status == 'draft' && page == 2) return true;
          else if (status == 'approved' && page == 3) return true;
          else if (status == 'paid' && page == 3) return true;
          return false;
        };
      }
    },
    mounted() {
      
    },
    methods: {
      reviewPayroll() {
        axios.get(`${config.api_path}/payroll/detail`,{
              params : {
                  payroll_id : this.payroll_id,
              }
          }).then(res => {
            const {data} = res.data;
            this.payroll_data = data;

            if(data.length) {
              this.payroll_status = data[0].status;
            }

            // if no record found
            if(!data.length) {
              this.$parent.reloadPage()
              return;
            }
            
            this.from = data[0].payroll_date_from;
            this.to = data[0].payroll_date_to;

            if(this.payroll_status == 'draft')
              this.formStepsNum = 2;
            else if(this.payroll_status == 'approved')
              this.formStepsNum = 3;
            else if(this.payroll_status == 'paid')
              this.formStepsNum = 3;
            
            this.updateFormSteps();
            this.updateProgressbar();
            this.shown['step'+(this.formStepsNum)] = true;
            if(this.formStepsNum != 3)
              this.shown['step3'] = false;
            })
      },
      generate(ev) {
        this.from = ev.from;
        this.to = ev.to;
        this.payroll_data = [];
          this.$showLoading(true);
          axios.get(`${config.api_path}/payroll/personnel-basic-info`,{
              params : {
                  from : moment(ev.from).format('YYYY-MM-DD'),
                  to : moment(ev.to).format("YYYY-MM-DD"),
                  company_id : config.global_user_data.company_id
              }
          }).then(res=> {
            this.$showLoading(false);
            const {data} = res.data;
            this.payroll_data = [...data];
            this.no_data = true;
            if(this.payroll_data.length) {
              this.no_data = false;
              this.nextBtn();
            }
          })
          .catch(err => {
            this.$showLoading(false);
            
          })
      },
      submit(status) {
        console.log(this.$refs.payroll_info.form)
        let payroll = this.$refs.payroll_info.form;
        let payroll_id = null;
        let payroll_arr = [];
        let overall_netpay = 0, 
        payroll_date_to = this.$refs.payroll_range.payroll_date_to,payroll_date_from = this.$refs.payroll_range.payroll_date_from, no_of_employee = 0 ,overall_earnings= 0, company_id = config.global_user_data.company_id;
        for (const key in payroll) {
          if (Object.hasOwnProperty.call(payroll, key)) {
            const element = payroll[key];
            for (const index in element) {
                if(element[index] == null)
                  element[index] = 0;
            }
            payroll_arr.push(element)
          }
        }
        no_of_employee = payroll_arr.length;
        for (let index = 0; index < payroll_arr.length; index++) {
            const element = payroll_arr[index];
            
            payroll_arr[index].total_deductions = this.computeTotalDeduction(element);
            payroll_arr[index].total_earnings = this.computeTotalEarnings(element)
            
            overall_earnings += parseFloat(payroll_arr[index].total_earnings);
            overall_netpay += parseFloat(element.net_pay);
            payroll_id = element.payroll_id ?? null;
            
        }

      
        payroll_date_from = (payroll_date_from) ? moment(payroll_date_from).format('YYYY-MM-DD') : '';
        payroll_date_to = (payroll_date_to) ? moment(payroll_date_to).format('YYYY-MM-DD') : '';
        
        let data = {
          company_id,
          overall_earnings,
          payroll_date_from,
          payroll_date_to,
          no_of_employee,
          status,
          overall_netpay,
          payroll_id
        }
        let params = {
          payroll_detail : payroll_arr,
          info : data
        }

        let url = `${config.api_path}/payroll/save-payroll`;
        if(payroll_id) {
          url = `${config.api_path}/payroll/update-payroll`
        }

        this.$showLoading(true)
        axios.post(url, params).then(res => {
          const {payroll_id} = res.data;
          this.$showLoading(false);
          if(status == 'approved')
            this.$router.push(`/main/new_payroll/${payroll_id}`)
          else
            this.$router.push('/main/payroll')
        }).catch(err => {
          this.$showLoading(false)
        })
      },
      computeTotalDeduction(emp) {
            let deduction = 0;
            deduction += (emp.rider_late != null) ? parseFloat(emp.rider_late) : 0;
            deduction += (emp.charges != null) ? parseFloat(emp.charges) : 0;
            deduction += (emp.meals != null) ? parseFloat(emp.meals) : 0;
            deduction += (emp.sss != null) ? parseFloat(emp.sss) : 0;
            deduction += (emp.sss_loan != null) ? parseFloat(emp.sss_loan) : 0;
            deduction += (emp.phic != null) ? parseFloat(emp.phic) : 0;
            deduction += (emp.pag_ibig != null) ? parseFloat(emp.pag_ibig) : 0;
            deduction += (emp.pag_ibig_stl != null) ? parseFloat(emp.pag_ibig_stl) : 0;
            deduction += (emp.utilities != null) ? parseFloat(emp.utilities) : 0;
            deduction += (emp.cash_advance != null) ? parseFloat(emp.cash_advance) : 0;
            deduction += (emp.other_deductions != null) ? parseFloat(emp.other_deductions) : 0;
            return deduction;
        },
        computeTotalEarnings(emp) {
            let earning = 0;
            earning += (emp.basic_salary_amount != null) ? parseFloat(emp.basic_salary_amount) : 0;
            earning += (emp.allowance != null) ? parseFloat(emp.allowance) : 0;
            earning += (emp.incentives != null) ? parseFloat(emp.incentives) : 0;
            earning += (emp.holiday_qty_amount != null) ? parseFloat(emp.holiday_qty_amount) : 0;
            earning += (emp.overtime != null) ? parseFloat(emp.overtime) : 0;
            earning += (emp.adjustment != null) ? parseFloat(emp.adjustment) : 0;
            return earning;
        },
        nextBtn() {
          this.formStepsNum++;
          this.updateFormSteps();
          this.updateProgressbar();
          this.shown['step'+(this.formStepsNum)] = true;
          if(this.formStepsNum != 3)
            this.shown['step3'] = false;
        },
        prevBtn(param = null) {
          this.formStepsNum--;
          this.updateFormSteps();
          this.updateProgressbar();
          if(this.formStepsNum != 3)
            this.shown['step3'] = false;

          if(param?.from == 'step2') {
            this.shown.step2 = false;
          }
        },
        updateFormSteps() {
          const formSteps = document.querySelectorAll(".form-step");
          formSteps.forEach((formStep) => {
            formStep.classList.contains("form-step-active") &&
              formStep.classList.remove("form-step-active");
          });

          formSteps[this.formStepsNum-1].classList.add("form-step-active");
        },
        updateProgressbar() {
          const progressSteps = document.querySelectorAll(".progress-step");
          progressSteps.forEach((progressStep, idx) => {
            if (idx < this.formStepsNum) {
              progressStep.classList.add("progress-step-active");
            } else {
              progressStep.classList.remove("progress-step-active");
            }
          });

          const progress = document.getElementById("progress");
          const progressActive = document.querySelectorAll(".progress-step-active");
          // this.progressActive = document.querySelectorAll(".progress-step-active");
          progress.style.width = ((progressActive.length - 1) / (progressSteps.length - 1)) * 100 + "%";
          
          // console.log(progressActive.length)
        }

    }
}
</script>
<style scoped>
:root {
  --primary-color: rgb(11, 78, 179);
}
label {
  display: block;
  margin-bottom: 0.5rem;
}

input {
  display: block;
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
}

.width-50 {
  width: 50%;
}


.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}


.text-center {
  text-align: center;
}

/* Progressbar */
.progressbar {
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin: 0 0 2.5rem;
}

.progressbar::before,
.progress {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%) !important;
  height: 4px;
  width: 100%;
  background-color: #c6c6c6;
  z-index: 1;
}

.progress {
  background-color:  rgb(11, 78, 179);
  width: 0%;
  transition: 0.3s;
}

.progress-step {
  width: 2.1875rem;
  height: 2.1875rem;
  background-color: #dcdcdc;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.progress-step::before {
  counter-increment: step;
  content: counter(step);
}

.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 0.85rem;
  font-weight: 600;
  color: #666;
}

.progress-step-active {
  background-color:  rgb(11, 78, 179);
  color: #ede1e1;
}

/* Form */
.form {
  width: 100%;
  margin: 0 auto;
  /* border: 1px solid rgb(73, 73, 73); */
  border-radius: 0.35rem;
  /* padding: 1.5rem; */
}

.form-step {
  display: none;
  transform-origin: top;
  /* animation: animate 0.5s; */
}

.form-step-active {
  display: block;
}

.input-group {
  margin: 2rem 0;
}

@keyframes animate {
  from {
    transform: scale(1, 0);
    opacity: 0;
  }
  to {
    transform: scale(1, 1);
    opacity: 1;
  }
}

/* Button */
.btns-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
   /* grid-template-columns: repeat(3, 2fr); */
  gap: 1.5rem;
}

.btn-size {
  line-height: 0.5rem;
  width: 20%;
}

.btn {
 
  padding: 0.75rem;
  display: block;
  text-decoration: none;
  background-color:  rgb(11, 78, 179);
  color: #f3f3f3;
  text-align: center;
  border-radius: 0.25rem;
  cursor: pointer;
  transition: 0.3s;
}
.btn:hover {
  box-shadow: 0 0 0 2px #fff, 0 0 0 3px  rgb(11, 78, 179);
}
</style>