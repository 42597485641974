<template>
    <div> 
        <div class="row">
            <div class="col-md-4 pl-4" style="margin-top:-5px">
                <h6>Payroll Period</h6>
                <h5 class="mb-1">{{from | date_format}} - {{to | date_format}}</h5>
            </div>
            <div class="col-md-4 pl-4 text-center">
                <h4 class="text-center">Process Payroll</h4>
            </div>
            <div class="col-md-4 text-right">
                <template v-if="$parent.isPrevBtnDisabled(status, 2)">
                    <a href="javascript:void(0)" class="btn btn-info  mr-2 btn-sm disabled">
                        <i class="fa fa-chevron-left"></i> Previous
                    </a>
                </template>
                <template v-else>
                    <a href="javascript:void(0)" class="btn btn-info mr-2 btn-sm" @click="prevBtn">
                        <i class="fa fa-chevron-left"></i> Previous
                    </a>
                </template>
                <template v-if="included_emp.length == 0">
                    <a href="javascript:void(0)" class="btn btn-info px-4 btn-sm disabled">
                        Next <i class="fa fa-chevron-right"></i> 
                    </a>
                </template>
                <template v-else>
                    <a href="javascript:void(0)" class="btn btn-info px-4 btn-sm" @click="nextBtn">
                        Next <i class="fa fa-chevron-right"></i> 
                    </a>
                </template>
                
            </div>
            
        </div>
        <div class="row mt-2" v-if="employee_with_errors.length && show_alert">
            <div class="col-md-12">
                <div class="alert alert-danger" role="alert">
                    <h4 class="alert-heading">{{ employee_with_errors.length }} {{employee_with_errors.length > 1 ? 'employees' : 'employee'}} encountered an error! <strong>NET PAY</strong> must be greater than (0) zero.</h4>
                    <ul>
                        <li v-for="(epm,index) in employee_with_errors" :key="index">
                            {{ epm.personnel_name }}
                        </li>
                    </ul>
                    <hr>
                    <small class="mb-0"><em>Payroll changes done in other modules (Charges, Cash Advance, etc.) will not reflect automatically. Regenerate payroll to see changes.</em></small>
                    <button type="button" class="btn-close btn btn-lg" @click="removeAlert" style="position:absolute;top:5px;right:5px" aria-label="Close">
                        <i class="fa fa-times"></i>
                    </button>
                </div>
            </div>
        </div>
        <CRow>
                <CCol lg="3" class="pr-0 pb-4">
                     <CCard class="p-2" style="min-height:100%;max-height:200px">
                        <div class="card-header pb-2 pt-0">
                            <div class="form-check">
                                <input class="form-check-input" @click="selectAllchk" ref="chk_all" type="checkbox" :checked="allIsSelected">
                                <label class="form-check-label">
                                    <strong class="text-dark">Employee</strong>
                                </label>
                            </div>
                            
                        </div>
                        <ul class="list-group list-group-flush" style="overflow-y: auto">
                            <li class="list-group-item" :class="{'list-group-item-info' : form[selected_personnel_id] && (form[selected_personnel_id].personnel_id) == emp.personnel_id}" v-for="(emp, index) in employee_list" :key="index">
                                <div class="form-check">
                                    <input @click="$event => empSelected($event, emp)" class="form-check-input" :checked="isChecked(emp.personnel_id)" type="checkbox" :value="emp.personnel_id" :id="emp.personnel_id">
                                    <label class="form-check-label">
                                        <a href="javascript:void(0)" class="text-danger" v-if="hasError(emp.personnel_id)"  @click="selectEmployee(emp)">{{emp.personnel_name}}</a>
                                        <a href="javascript:void(0)" v-else :class="{'text-dark' : form[selected_personnel_id] && (form[selected_personnel_id].personnel_id) != emp.personnel_id}" @click="selectEmployee(emp)">{{emp.personnel_name}}</a>
                                        
                                    </label>
                                </div>
                            </li>
                        </ul>
                           
                     </CCard>                
                 </CCol>
            
                <CCol lg="9" class="pl-2">
                <CCard class="p-2 mb-2">
                    <CRow class="ml-2 mr-2" v-if="form[selected_personnel_id]">
                        <CCol lg="6">
                            <small title="Employee ID" style="font-size:13px" class="mr-2">Name</small>
                            <h3 title="Employee Name">{{form[selected_personnel_id].personnel_name}}</h3>
                           
                        </CCol>
                        <CCol lg="2">
                            <small style="font-size:13px" class="mr-2">Personnel Type</small>
                             <h5>{{form[selected_personnel_id].personnel_type}}</h5>
                        
                        </CCol>
                        <CCol lg="2">
                            
                            <small style="font-size:13px" class="mr-2">Daily Rate</small>
                             <h5>{{form[selected_personnel_id].daily_rate}}</h5>
                        </CCol>
                        <CCol lg="2">
                            <div class="form-group">
                                <small style="font-size:13px" >Payment Method</small>
                                <select class="form-control" v-model="form[selected_personnel_id].payment_method">
                                    <option value=""></option>
                                    <option value="ATM">ATM</option>
                                    <option value="CASH">CASH</option>
                                </select>
                            </div>
                            
                        </CCol>
    
                                
                    </CRow>
                    <CRow class="ml-2 mr-2">
                        
    
                    </CRow>
                </CCard>
                <CCard class="p-2">
            <CRow>
               
                <CCol lg="6">
                <fieldset class="fieldset full-line rounded">
                        <legend>Earnings</legend>
                        <CRow >
                                    <CCol lg="12" class="mt-0">
                                        <div class="row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;"></label>
                                            </div>  
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;" class="col-form-label">Trips/Days/Hrs</label>
                                            </div>
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;" class="col-form-label">Amount</label>
                                            </div>
                                           
                    
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                                    <CCol lg="12">
                                        <div class="mb-2 row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;">Basic Salary</label>
                                             </div>
                                             <div class="col-sm-4">
                                            <input type="number"  class="form-control" v-model="form[selected_personnel_id].basic_salary_qty" > 
                                            </div>
                                            <div class="col-sm-4">
                                            <input disabled type="number" class="form-control" v-model="form[selected_personnel_id].basic_salary_amount" > 
                                            </div>
                                            
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                                    <CCol lg="12">
                                        <div class="mb-2 row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;">Incentives</label>
                                             </div>
                                             <div class="col-sm-4">
                                            <input type="number" v-model="form[selected_personnel_id].incentives" class="form-control" > 
                                            </div>
                                            <div class="col-sm-4">
                                                <input disabled placeholder="-" type="number" class="form-control" > 
                                            </div>
                                           
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                                    <CCol lg="12">
                                        <div class="mb-2 row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;">Holiday Pay</label>
                                             </div>
                                             <div class="col-sm-4">
                                            <input type="number" class="form-control" v-model="form[selected_personnel_id].holiday_qty" > 
                                            </div>

                                            <div class="col-sm-4">
                                            <input type="number" class="form-control" @keyup="earningsChange($event, 'holiday_qty_amount')" :value="form[selected_personnel_id].holiday_qty_amount"> 
                                            </div>
                                           
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                                    <CCol lg="12">
                                        <div class="mb-2 row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;">Overtime</label>
                                             </div>
                                             <div class="col-sm-4">
                                            <input type="number" disabled class="form-control"> 
                                            </div>

        
                                            <div class="col-sm-4">
                                            <input disabled type="number" class="form-control" :value="form[selected_personnel_id].overtime" > 
                                            </div>
                                           
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                                    <CCol lg="12">
                                        <div class="mb-2 row">
                                            <div class="col-sm-4">
                                            <label style="font-size: 13px;">Allowance</label>
                                             </div>
                                             <div class="col-sm-4">
                                            <input type="number" placeholder="-" disabled class="form-control"> 
                                            </div>

        
                                            <div class="col-sm-4">
                                            <input type="number" class="form-control"  
                                                @keyup="earningsChange($event, 'allowance')" :value="form[selected_personnel_id].allowance" > 
                                            </div>
                                           
                                      </div>

                                </CCol>
                        </CRow>
                        <CRow >
                            <CCol lg="8">
                                <div class="mb-0">
                                    <label class="mb-1" style="font-size: 13px;">Adjustment</label>
                                    <div class="col-sm-12 pl-0">
                                        <v-select
                                            v-model="form[selected_personnel_id].adjustment_id"
                                            multiple
                                            placeholder=""
                                            label="label"
                                            @input="adjustmentChanged"
                                            :options="adjustmentList(form[selected_personnel_id].adjustments)"
                                            
                                        />
                                        <!-- <select class="form-control" @change="adjustmentChanged" v-model="form[selected_personnel_id].adjustment_id">
                                            <option value=""></option>
                                            <option :value="adj.id" v-for="(adj, i) of adjustmentList(form[selected_personnel_id].adjustments)" :key="i">
                                                {{adj.label}}
                                            </option>
                                        </select> -->
                                    </div>
                                </div>
                            </CCol>
                            <CCol lg="4">
                                <label class="mb-1" style="font-size: 13px;">&nbsp; </label>
                                <input disabled type="number" v-model="adjustment_total[selected_personnel_id]" class="form-control" > 
                                <input disabled type="number" v-model="dummy_var[selected_personnel_id]" class="form-control d-none" >     
                            </CCol>
                        </CRow>

                </fieldset>
                <fieldset class="fieldset full-line rounded">
                        <legend>Earnings Summary</legend>
                       
            <CRow >
			    <CCol lg="6">
				    <strong style="font-size:15px"> Total Earnings</strong>
		    	</CCol>
			    <CCol lg="6" class="text-left">
			        <strong style="font-size:15px" >{{ computeTotalEarnings(form[selected_personnel_id]) | number_format('currency')}}</strong>
			    </CCol>
		    </CRow>   
            <CRow >
			    <CCol lg="6">
				    <strong style="font-size:15px"> Total Deductions</strong>
		    	</CCol>
			    <CCol lg="6" class="text-left">
			        <strong style="font-size:15px">{{ computeTotalDeduction(form[selected_personnel_id]) | number_format('currency')}}</strong>
			    </CCol>
		    </CRow>        
             <CRow class="pt-0 mb-2 bg-secondary text-dark">
			    <CCol lg="6">
				    <strong style="font-size:18px"> NET PAY</strong>
		    	</CCol>
			    <CCol lg="6" class="text-left">
			        <strong style="font-size:18px">{{(computeTotalEarnings(form[selected_personnel_id]) - computeTotalDeduction(form[selected_personnel_id])) | number_format('currency')}}</strong>
			    </CCol>
		    </CRow>
               
            </fieldset>
               
       </CCol>
                <CCol lg="6">
                        <fieldset class="fieldset full-line rounded">
                        <legend>Deductions</legend>
                                <CRow >
                                    <CCol lg="6" class="px-1" v-for="(item, index) of deductions" :key="index">
                                        <div class="mb-1">
                                            <small :for="item.model" class="col-sm-3">{{item.label}}</small>
                                            <div class="col-sm-12">
                                            <input type="text"  @keypress="isNumber($event)" class="form-control" :id="item.model" @keyup="deductionChange($event, item.model)" :value="form[selected_personnel_id][item.model]">
                                            </div>
                                        </div>
                                    </CCol>
                                    <CCol lg="6" class="text-left">
                                        <strong style="font-size:12px"> Total Deductions</strong>
                                        <h2>{{ computeTotalDeduction(form[selected_personnel_id]) | number_format('currency') }}</h2>
                                    </CCol>
                                </CRow>
                                
                             <CRow >
                                
		                     </CRow>      
                       </fieldset>
                       <CCol lg="12" class="pt-0 mb-2">
                    
                            <a href="javascript:void(0)" v-if="!isChecked(selected_personnel_id)" class="float-right btn btn-success px-2" @click="markPersonnel()">
                                <i class="fa fa-plus mr-1"></i>
                                Add to this payroll
                            </a>

                            <a href="javascript:void(0)" v-if="isChecked(selected_personnel_id)" class="float-right btn btn-danger px-2" @click="excludeFromList()">
                                <i class="fa fa-times mr-1"></i>
                                Exclude from this payroll
                            </a>
                     </CCol>  
                </CCol>
                
            </CRow>
            
        
                </CCard>           
                </CCol>
          
        </CRow>
            
    </div>
</template>
<script>
import config from '../../../config';
import axios from "../../../axios";
import Swal from 'sweetalert2';
import moment from 'moment';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

    export default {
        name : 'step2',
        data() {
            return {
                deductions: [
                    {
                        label : 'Rider Late',
                        model : 'rider_late'
                    },
                    {
                        label : 'Charges',
                        model : 'charges'
                    },
                    {
                        label : 'Meals',
                        model : 'meals'
                    },
                    {
                        label : 'SSS',
                        model : 'sss'
                    },
                    {
                        label : 'SSS Loan',
                        model : 'sss_loan'
                    },
                    {
                        label : 'PHIC',
                        model : 'phic'
                    },
                    {
                        label : 'Pag-ibig',
                        model : 'pag_ibig'
                    },
                    {
                        label : 'Pag-ibig STL',
                        model : 'pag_ibig_stl'
                    },
                    {
                        label : 'Utilities',
                        model : 'utilities'
                    },
                    {
                        label : 'Cash Advance',
                        model : 'cash_advance'
                    },
                    {
                        label : 'Other Deductions',
                        model : 'other_deductions'
                    }
                ],
                earnings_summary: [
                    {
                        label : 'Basic Salary',
                        model : 'basic_salary'
                    },
                    {
                        label : 'Holiday Pay',
                        model : 'holiday_qty'
                    },
                    {
                        label : 'Overtime',
                        model : 'overtime'
                    },
                    {
                        label : 'Allowance',
                        model : 'allowance'
                    },
                    {
                        label : 'Adjustment',
                        model : 'adjustment'
                    },
                    {
                        label : 'Incentive',
                        model : 'incentives'
                    }
                ],
                employee_list: [
                    
                ],
                form : {},
                included_emp: [],
                selected_personnel_id : '',
                models: [],
                adjustment_total : {},
                dummy_var: {},
                employee_with_errors: [],
                show_alert : true
               
            }
        },
        components: {vSelect},
        props: ['payroll','from','to','status'],
        watch: {
            payroll : {
                handler(val) {
                    console.log('WATCH')
                    if(val) {
                        // this.init()
                    }
                },
                deep : false
            },
            
        },
        beforeMount() {
            this.init()          
        },
        computed : {
            hasError() {
                return id => {
                    let with_error = this.employee_with_errors.filter(v => v.personnel_id == id);

                    return with_error.length ? true : false
                }
            },
            adjustmentList() {
                return adjustment => {
                    if(adjustment) {
                        let adj = adjustment.split('|');
                        let arr = [];
                        for (let index = 0; index < adj.length; index++) {
                            const element = adj[index].split('_');
                            arr.push({
                                id : element[0],
                                label : element[1],
                                rate : element[2]
                            });
                        }

                        return arr;
                    }
                    else {
                        this.form[this.selected_personnel_id].adjustment_id = null;
                        return [];
                    }
                }
            },
            allIsSelected() {
                return this.included_emp.length == this.employee_list.length
            },
            isChecked() {
                return id => this.included_emp.indexOf(id) >= 0
            },
            
        },
        methods: {
            removeAlert() {
                this.show_alert = false;
            },
            init() {
                this.form = {};
                this.included_emp = [];
                this.selected_personnel_id = '';
                this.models = [];
                this.adjustment_total = {};

                this.employee_list = [...this.payroll];

                if(this.employee_list.length) {
                    this.form[this.employee_list[0].personnel_id] = {...this.employee_list[0]};
                    this.selected_personnel_id = this.employee_list[0].personnel_id;

                    // if not view payroll
                    if(!this.employee_list[0].id){
                        this.deductions.forEach(d => {
                            this.form[this.selected_personnel_id][d.model] = this.form[this.selected_personnel_id][d.model] ?? null;
                            this.models[d.model] = null;
                        })
                        this.earnings_summary.forEach(d => {
                            this.form[this.selected_personnel_id][d.model] = this.form[this.selected_personnel_id][d.model] ?? null;
                            this.models[d.model] = null;
                        })
                    }
                    else {
                        this.adjustment_total[this.selected_personnel_id] = this.form[this.employee_list[0].personnel_id].adjustment;
                        this.dummy_var[this.selected_personnel_id] = this.form[this.employee_list[0].personnel_id].adjustment;
                        this.selectAll(true)
                    }

                    
                }  
                else {
                    this.employee_list = {};
                }

                // net pay trapping
                for (let index = 0; index < this.employee_list.length; index++) {
                    const element = this.employee_list[index];
                    let netpay = (this.computeTotalEarnings(element) - this.computeTotalDeduction(element));
                    if(netpay < 0) {
                        this.employee_with_errors.push(element);
                    }
                }
            },
            earningsChange(event, model) {
                let val = event.target.value;
                if(val == '')
                    val = 0;
                else if(val >= 0)
                    val = parseFloat(val);

                this.$set(this.form[this.selected_personnel_id],model, val)
                this.$set(this.dummy_var, this.selected_personnel_id, Math.random())
               
            },
            deductionChange(event, model) {
                let val = event.target.value;
                
                if(val == '')
                    this.$set(this.form[this.selected_personnel_id],model, 0)
                else if(val >= 0)
                    this.$set(this.form[this.selected_personnel_id],model, val)
                
                this.$set(this.dummy_var, this.selected_personnel_id, Math.random())
               
            },
            adjustmentChanged(adjustment) {
                console.log(this.form[this.selected_personnel_id], adjustment)
                let rate = 0;
                if(adjustment?.length) {
                   
                    adjustment.forEach(a => {
                        rate += (a.rate) ? parseFloat(a.rate) : 0;
                    })

                    this.$set(this.form[this.selected_personnel_id],'adjustment', rate);
                    
                }
                else {
                    this.form[this.selected_personnel_id].adjustment = 0;
                    // this.form[this.selected_personnel_id].adjustment_id = [];
                }

                this.$set(this.adjustment_total, this.selected_personnel_id, rate)
               
                
            },
            prevBtn() {
                Swal.fire({
				icon:'warning',
				title: 'Are you sure you want to go back?',
                text: 'Going back will reset your current work.', 
				showCancelButton: true,
				confirmButtonText: `Yes, go back`, 
				
        		reverseButtons: false,

			}).then((result) => { 
				if (result.isConfirmed) {

                    this.$emit('prev',{from:'step2'})
                    // this.init(true);
				}
			})  
                
            },
            nextBtn() {
                
                this.employee_with_errors = [];
                this.show_alert = true;
                if(this.included_emp.length == 0) return;

                console.log(this.included_emp)

                for (let index = 0; index < this.included_emp.length; index++) {
                    const element = this.included_emp[index];
                    let netpay = (this.computeTotalEarnings(this.form[element]) - this.computeTotalDeduction(this.form[element]));
                    console.log(this.form[element]);
                    console.log('NET PAY', netpay)
                    if(netpay < 0) {
                        this.employee_with_errors.push(this.form[element]);
                    }

                }

                if(this.employee_with_errors.length > 0) return;

                if(this.included_emp.length < this.employee_list.length) {
                    Swal.fire({
                    icon: "warning",
                    title:"Warning!",
                    text: "You have excluded a personnel in this payroll.",
                    showCancelButton: true,
                    cancelButtonColor: 'red',
                    reverseButtons: true,
                    confirmButtonText: `Continue`,
                    allowOutsideClick: false,

                }).then((result) => {
                    if (result.isConfirmed) {
                        for (let index = 0; index < this.included_emp.length; index++) {
                            const element = this.included_emp[index];
                            // if (Object.hasOwnProperty.call(this.form, element)) {
                            //     delete this.form[element];
                            // }

                            for (const key in this.form) {
                                const element = this.form[key];
                                if(this.included_emp.indexOf(element.personnel_id) < 0) {
                                    delete this.form[key];
                                }
                            }
                            
                        }
                        
                        this.$emit('next')
                    }
                });
                }
                else {
                    this.$emit('next')
                }
                
                
            },
            computeTotalDeduction(emp) {
                return this.$parent.computeTotalDeduction(emp);
            },
            computeTotalEarnings(emp) {
                return this.$parent.computeTotalEarnings(emp)
            },
            excludeFromList() {
                this.included_emp.splice(this.included_emp.indexOf(this.selected_personnel_id), 1);
            },
            markPersonnel() {
                this.included_emp.push(this.selected_personnel_id)
            },
            
            selectAllchk(ev) {
                this.selectAll(ev.target.checked)          
                
            },
            selectAll(checked) {
                this.included_emp = []; 
                if(checked) {
                    this.employee_list.forEach(e => {
                        this.included_emp.push(e.personnel_id)
                        if (!Object.hasOwnProperty.call(this.form, e.personnel_id)) {
                            this.form[e.personnel_id] = e;
                        }
                    })
                } 
            },
            selectEmployee(emp) {
                this.selected_personnel_id = emp.personnel_id;
                // check if personnel already saved in form variable. to avoid resetting/overwriting inputted values
                if (!Object.hasOwnProperty.call(this.form, emp.personnel_id)) {
                    this.form[emp.personnel_id] = {...this.models, ...emp};
                    this.form[emp.personnel_id].adjustment_id=[];
                }
            },
            
            empSelected(ev, emp) {
                const checked = ev.target.checked;
                if(checked) {
                    this.included_emp.push(emp.personnel_id);
                    if (!Object.hasOwnProperty.call(this.form, emp.personnel_id)) {
                        this.form[emp.personnel_id] = {...emp};
                    }
                }
                else
                    this.included_emp.splice(this.included_emp.indexOf(emp.personnel_id), 1)

                this.$refs.chk_all.checked = (this.included_emp.length == this.employee_list.length);
            }
        }
    }
    </script>

    <style scoped>
li.select2-selection__choice {
    max-width: 100%;
    overflow: hidden;
    /*Altered two below to make word wrap work */
    word-wrap: normal !important;
    white-space: normal;
}
ul.select2-selection__rendered {
    padding-right: 12px !important;
}
</style>